import './App.css';
import './localization/localizations';
import {getTranslation, Message} from "./localization/localizations";

const App = () => {
    return <div className="container">
        <div className="logo">
            <img src="favicon.png" alt="Polyingo - Learn Languages Interactively"/>
        </div>
        <h1 className="title">{getTranslation('title')}</h1>
        <p className="subtitle">{getTranslation('subtitle')}</p>
        <meta name="description"
              content="Polyingo is an innovative language learning app where you can watch pronunciation videos, chat with AI, and play interactive games to boost your vocabulary."/>
        <meta name="keywords"
              content="language learning, learn English, vocabulary games, AI language tutor, pronunciation practice, Polyingo app"/>
        <meta name="robots" content="index, follow"/>
        <Message/>
        <hr className="footer-divider"/>
        <div className="buttons">
            <a href="https://play.google.com/store/apps/details?id=com.polyingo" tabIndex="0">
                <img className="store-button"
                     src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                     alt="Get Polyingo on Google Play Store"/>
            </a>
            <a href="https://apps.apple.com/us/app/polyingo/id1462656360" tabIndex="0">
                <img className="store-button"
                     src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                     alt="Download Polyingo from Apple App Store"/>
            </a>
        </div>

        <footer className="footer">
            <div className="social-media">
                <a href="https://twitter.com/polyingo" className="social-icon twitter"
                   aria-label="Follow Polyingo on Twitter"></a>
                <a href="https://instagram.com/polyingo" className="social-icon instagram"
                   aria-label="Follow Polyingo on Instagram"></a>
                <a href="https://www.youtube.com/@Polyingo" className="social-icon youtube"
                   aria-label="Subscribe to Polyingo on YouTube"></a>
            </div>

            <hr className="footer-divider"/>
            <a href="https://www.polyingo.com/docs/privacy_policy_en.html" className="privacy-policy">
                {getTranslation('privacyPolicy')}
            </a>
        </footer>
    </div>;
};

export default App;
