const translations = {
    en: {
        title: "Polyingo",
        subtitle: "Interactive way to learn new languages.",
        message: "Watch and share real-life pronunciation videos, chat with AI or real people, and play interactive games to build your vocabulary. Set reminders, tag words, and customize your learning experience. Whether you're a beginner or advanced, Polyingo makes learning engaging and effective!",
        googlePlay: "Google Play",
        appStore: "App Store",
        privacyPolicy: "Privacy Policy",
        followUs: "Follow us"
    },
    tr: {
        title: "Polyingo",
        subtitle: "Yeni dilleri öğrenmenin interaktif yolu.",
        message: "Polyingo ile gerçek telaffuz videoları izleyin ve paylaşın, yapay zeka veya gerçek kişilerle sohbet edin, kelime bilginizi geliştirmek için interaktif oyunlar oynayın. Hatırlatıcılar ayarlayın, kelimeleri etiketleyin ve öğrenme deneyiminizi kişiselleştirin.",
        googlePlay: "Google Play",
        appStore: "App Store",
        privacyPolicy: "Gizlilik Politikası",
        followUs: "Bizi takip edin"
    }
};

const TurkishMessage = () => {
    return (
        <p className="advertising-text message">
            <span className="highlight-underline">Polyingo</span> ile <span className="highlight-bg">gerçek telaffuz videoları</span> izleyin ve paylaşın,
            <span className="highlight-underline">yapay zeka</span> veya <span className="highlight-underline">gerçek kişiler</span> ile sohbet edin,
            kelime bilginizi geliştirmek için <span className="highlight-bg">etkileşimli oyunlar</span> oynayın.
            Hatırlatıcılar ayarlayın, <span className="highlight-underline">kelimeleri etiketleyin</span> ve öğrenme deneyiminizi kişiselleştirin.
            İster <span className="highlight-bg">yeni başlayan</span> olun ister <span className="highlight-bg">ileri düzeyde</span>,
            <span className="highlight-underline">Polyingo</span> ile öğrenmek her zaman <span className="highlight-underline">keyifli</span> ve <span className="highlight-underline">etkili</span>! 🚀
        </p>
    );
};


const EnglishMessage = () => {
    return (
        <p className="advertising-text message">
            Watch and share{' '}
            <span className="highlight-bg">real-life pronunciation videos</span>, chat with{' '}
            <span className="highlight-underline">AI</span> or <span className="highlight-underline">real people</span>,
            and play <span className="highlight-bg">interactive games</span> to build your vocabulary.
            Set reminders, <span className="highlight-underline">tag words</span>, and customize your learning experience.
            Whether you're a <span className="highlight-bg">beginner</span> or <span className="highlight-bg">advanced learner</span>,
            Polyingo makes learning <span className="highlight-underline">engaging</span> and <span className="highlight-underline">effective</span>!
        </p>
    );
};

const Message = () => {
    const userLang = navigator.language.split('-')[0];
    if (userLang === 'en') {
        return <EnglishMessage/>
    } else {
        return <TurkishMessage/>
    }
}

function getTranslation(text) {
    const userLang = navigator.language.split('-')[0];
    return translations[userLang]?.[text] || translations['en']?.[text] || text;
}

export {getTranslation, Message};